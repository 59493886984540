const AffixTable = new Map ([
	[68, {
		"Name": "普通攻擊總傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[67, {
		"Name": "彈射總技能傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[66, {
		"Name": "旋轉總技能傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[65, {
		"Name": "自我施法總技能傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[64, {
		"Name": "投射物總技能傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[63, {
		"Name": "地面總技能傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[62, {
		"Name": "指定總技能傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[61, {
		"Name": "傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[60, {
		"Name": "[MVP] 總技能傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[29, {
		"Name": "投射物總物理傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[28, {
		"Name": "遠距總物理傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[27, {
		"Name": "近戰總物理傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[26, {
		"Name": "每次擊殺精英魔物時，獲得此增益，持續4秒「總傷害{}」",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[25, {
		"Name": "每當護身符被動寶石效果觸發時，獲得此增益，持續12秒「總傷害{}」",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[24, {
		"Name": "在2秒內擊殺10隻以上的敵人時，獲得此增益，持續12秒「總傷害{}」",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[23, {
		"Name": "暴擊發動時，獲得此增益，持續12秒「總傷害{}」",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[22, {
		"Name": "投射物總魔法傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[21, {
		"Name": "屬性總魔法傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[20, {
		"Name": "總生命值",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[19, {
		"Name": "生命值",
		"ImplementWay": "Add"
	}],
	[18, {
		"Name": "移動速度",
		"Percentage": true,
		"ImplementWay": "Add"
	}],
	[17, {
		"Name": "全域暴擊率",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[16, {
		"Name": "附加水屬性傷害",
		"ImplementWay": "Add"
	}],
	[15, {
		"Name": "附加火屬性傷害",
		"ImplementWay": "Add"
	}],
	[14, {
		"Name": "附加風屬性傷害",
		"ImplementWay": "Add"
	}],
	[1, {
		"Name": "力量",
		"ImplementWay": "Add"
	}],
	[2, {
		"Name": "智力",
		"ImplementWay": "Add"
	}],
	[3, {
		"Name": "敏捷",
		"ImplementWay": "Add"
	}],
	[4, {
		"Name": "基礎傷害",
		"ImplementWay": "Add"
	}],
	[5, {
		"Name": "總基礎傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[6, {
		"Name": "物理基礎傷害",
		"ImplementWay": "Add"
	}],
	[7, {
		"Name": "總物理基礎傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[8, {
		"Name": "魔法基礎傷害",
		"ImplementWay": "Add"
	}],
	[9, {
		"Name": "總魔法基礎傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[10, {
		"Name": "物理傷害",
		"ImplementWay": "Add"
	}],
	[11, {
		"Name": "總物理傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[12, {
		"Name": "魔法傷害",
		"ImplementWay": "Add"
	}],
	[13, {
		"Name": "總魔法傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[30, {
		"Name": "總傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[31, {
		"Name": "承受物理傷害",
		"Percentage": true,
		"ImplementWay": "Sub"
	}],
	[32, {
		"Name": "技能冷卻時間",
		"Percentage": true,
		"ImplementWay": "Sub"
	}],
	[33, {
		"Name": "[祕境原力] 主要素質",
		"ImplementWay": "Add"
	}],
	[34, {
		"Name": "[精粹原力] 主要素質",
		"ImplementWay": "Add"
	}],
	[35, {
		"Name": "[古代原力] 主要素質",
		"ImplementWay": "Add"
	}],
	[36, {
		"Name": "[傳說原力] 主要素質",
		"ImplementWay": "Add"
	}],
	[37, {
		"Name": "[祕境原力] 基礎傷害",
		"ImplementWay": "Add"
	}],
	[38, {
		"Name": "[精粹原力] 基礎傷害",
		"ImplementWay": "Add"
	}],
	[39, {
		"Name": "[古代原力] 基礎傷害",
		"ImplementWay": "Add"
	}],
	[40, {
		"Name": "[傳說原力] 基礎傷害",
		"ImplementWay": "Add"
	}],
	[41, {
		"Name": "[祕境原力] 抵抗能力",
		"Percentage": true,
		"ImplementWay": "Add"
	}],
	[42, {
		"Name": "[精粹原力] 抵抗能力",
		"Percentage": true,
		"ImplementWay": "Add"
	}],
	[43, {
		"Name": "[古代原力] 抵抗能力",
		"Percentage": true,
		"ImplementWay": "Add"
	}],
	[44, {
		"Name": "[傳說原力] 抵抗能力",
		"Percentage": true,
		"ImplementWay": "Add"
	}],
	[45, {
		"Name": "[祕境原力] 總暴擊傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[46, {
		"Name": "[精粹原力] 總暴擊傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[47, {
		"Name": "[古代原力] 總暴擊傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[48, {
		"Name": "[傳說原力] 總暴擊傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[49, {
		"Name": "總力量Str",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[50, {
		"Name": "總敏捷Agi",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[51, {
		"Name": "總智力Int",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[52, {
		"Name": "[MVP] 力量",
		"ImplementWay": "Add"
	}],
	[53, {
		"Name": "[MVP] 智力",
		"ImplementWay": "Add"
	}],
	[54, {
		"Name": "[MVP] 敏捷",
		"ImplementWay": "Add"
	}],
	[55, {
		"Name": "[MVP] 近戰總物理傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[56, {
		"Name": "[MVP] 遠距總物理傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[57, {
		"Name": "[MVP] 總魔法傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[58, {
		"Name": "[MVP] 總屬性傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	[59, {
		"Name": "[MVP] 總無屬傷害",
		"Percentage": true,
		"ImplementWay": "Multiply"
	}],
	])
	export default AffixTable
	