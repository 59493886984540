import './Gem.css'

const CardInfo = (props) => {
    const effects = Object.entries(props.card.QualityInfos).map(([key, info], index, array) => {
      	return (
				<span className={`card__power__${key}`} key={key}>{info.Effect}{index < array.length - 1 ? '/' : null}</span>
			);
		}
    );

	let text = props.card.Info;
    const parts = text.split("{Effect}");

    return (
      <div className='card__description'>
        {parts.map((part, index) => 
          <span key={index}>
            {part}
            {index < parts.length - 1 ? effects : null}
          </span>
        )}
      </div>
    );
}

const Card = (props) => {
	return (
		<div className="card">
			<div className="card__top">
				<img
					src={"img/large/"+4001+".png"}
					alt={props.card.CardID}
					className="card__top__img"
				/>
				<div>
				<h2 className="card__top__title">{props.card.MobName}卡片</h2>
				<span className="card__power__1">普通</span>/<span className="card__power__2">精良</span>/<span className="card__power__3">稀有</span>/<span className="card__power__4">傳說</span>
				</div>
			</div>
			<CardInfo card={props.card}/>
		</div>
	);
}

export default Card;