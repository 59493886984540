import Gem from './Gem';
import Equip from './Equip';
import Card from './Card';
import Page from './Page';
import GemFlagFilter from './GemFlagFilter';
import './App.css';
import gems from './data/gems.js';
import equips from './data/equips.js';
import cards from './data/cards.js';
import { useState } from 'react';

function App() {
	const Pages = [
        {filter:"AllGem", name:"所有寶石", type:"Gem"},
        {filter:"Skill", name:"技能寶石", type:"Gem"}, 
        {filter:"Support", name:"輔助寶石", type:"Gem"}, 
        {filter:"Passive", name:"被動寶石", type:"Gem"},
        {filter:"Sword", name:"劍", type:"Equip", subtype:"Weapon"},
        {filter:"Bow", name:"弓", type:"Equip", subtype:"Weapon"},
        {filter:"Staff", name:"手杖", type:"Equip", subtype:"Weapon"},
        {filter:"Armor", name:"鎧甲", type:"Equip", subtype:"Armor"},
        {filter:"Shield", name:"盾牌", type:"Equip", subtype:"Armor"},
        {filter:"Shoes", name:"長靴", type:"Equip", subtype:"Armor"},
        {filter:"Garment", name:"披肩", type:"Equip", subtype:"Armor"},
        {filter:"Head", name:"頭具", type:"Equip", subtype:"Armor"},
        {filter:"Acc", name:"裝飾品", type:"Equip", subtype:"Armor"},
        {filter:"Card", name:"卡片", type:"Card",}
    ]

	const [nowPage, setPage] = useState(Pages[0]);
	const [nowFlagFilter, setFlagFilter] = useState(new Set());

	const changePageHandler = (event) => {
		const selectedPage = event.target.value;
		Pages.forEach( page => {
			if (page.filter === selectedPage) {
				setPage( prevPage => {
					return page;
				});
			}
		});
	}

	const changeFlagFilterHandler = (event) => {
		setFlagFilter(prevState => {
			let newState = new Set(prevState);
			const value = event.target.value;

			if (newState.has(value))
				newState.delete(value);
			else
				newState.add(value);

			return newState;
		});
	}

	const filteredGems = gems.filter(gem => {
		if (nowPage.filter === "AllGem" || gem.Type === nowPage.filter) {
			if (!nowFlagFilter || !nowFlagFilter.size)
				return true;
			else {
				let found = true;
				nowFlagFilter.forEach(flag => {
					if (!gem.Flags.has(flag))
						found = false;
				});

				if (found)
					return true;
			}
		}

		return false;
	});

	const filteredEquip = equips.filter(equip => {
		return equip.SubType === nowPage.filter;
	});

	const filteredCard = cards.filter(card => {
		return card.Type === nowPage.filter;
	});

	return (
		<div>
			<Page changePage={changePageHandler} nowPage={nowPage} pages={Pages}/>
			<GemFlagFilter nowFilter={nowFlagFilter} changeFlag={changeFlagFilterHandler} />
			<section className='rails'>
				{filteredGems.map(gem =>
					<Gem key={gem.ItemID} gem={gem} changeFlag={changeFlagFilterHandler} nowFlagFilter={nowFlagFilter} />
				)}
				{filteredEquip.map(equip =>
					<Equip key={equip.FirstItemID} equip={equip} />
				)}
				{filteredCard.map(card =>
					<Card key={card.CardID} card={card} />
				)}
			</section>
		</div>
	);
}

export default App;
