const data = [

    {ItemID: 50001,
    Name: "火箭術",
    Type: "Skill",
    SkillID: 19,
    CastTime: 0,
    Cooldown: 300,
    Flags: new Set(["魔法","指定","火屬性"]), 
    Description: "對目標造成150%傷害。"},
    {ItemID: 50002,
    Name: "冰箭術",
    Type: "Skill",
    SkillID: 14,
    CastTime: 0,
    Cooldown: 300,
    Flags: new Set(["魔法","指定","水屬性"]), 
    Description: "對目標造成150%傷害。"},
    {ItemID: 50003,
    Name: "雷擊術",
    Type: "Skill",
    SkillID: 20,
    CastTime: 0,
    Cooldown: 300,
    Flags: new Set(["魔法","指定","風屬性"]), 
    Description: "對目標造成150%傷害。"},
    {ItemID: 50004,
    Name: "連鎖閃電",
    Type: "Skill",
    SkillID: 2214,
    CastTime: 300,
    Cooldown: 3000,
    Flags: new Set(["魔法","指定","彈射","風屬性"]), 
    Description: "對目標造成300%傷害，並彈射給5個對象，同時使目標陷入麻痹狀態15秒。"},
    {ItemID: 50005,
    Name: "隕石術",
    Type: "Skill",
    SkillID: 83,
    CastTime: 500,
    Cooldown: 5000,
    Flags: new Set(["魔法","地面","範圍傷害","火屬性"]), 
    Description: "在地面上召喚一個巨型隕石降落，對臨近中心點4格內的目標造成400%傷害，同時使目標陷入燃燒狀態15秒。"},
    {ItemID: 50006,
    Name: "霜凍之術",
    Type: "Skill",
    SkillID: 88,
    CastTime: 300,
    Cooldown: 3000,
    Flags: new Set(["魔法","自我施法","範圍傷害","水屬性"]), 
    Description: "以自身為中心，對臨近4格內的目標造成200%傷害並陷入冰封狀態15秒。"},
    {ItemID: 50007,
    Name: "暴風雪",
    Type: "Skill",
    SkillID: 89,
    CastTime: 500,
    Cooldown: 5000,
    Flags: new Set(["魔法","地面","範圍傷害","DOT","水屬性"]), 
    Description: "在指定地面的4格以內的區域，召喚歷時5秒的暴風雪，此區域內每0.4秒造成50%的傷害，同時使目標陷入冰封狀態15秒。"},
    {ItemID: 50008,
    Name: "火球術",
    Type: "Skill",
    SkillID: 17,
    CastTime: 300,
    Cooldown: 1500,
    Flags: new Set(["魔法","範圍傷害","投射物","火屬性"]), 
    Description: "朝向一個方向擲出火球，撞擊到第一個目標時，造成對臨近第一個目標2格內的所有目標，造成175%傷害，同時使目標陷入燃燒狀態15秒。"},
    {ItemID: 50009,
    Name: "電裂術",
    Type: "Skill",
    SkillID: 2444,
    CastTime: 500,
    Cooldown: 1500,
    Flags: new Set(["魔法","範圍傷害","投射物","風屬性"]), 
    Description: "朝一個方向召喚出一道具有穿透性的閃電，對每個被閃電穿透的目標造成200%的傷害並陷入痲痺狀態 15 秒。"},
    {ItemID: 50010,
    Name: "火屬性增強(5)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["火屬性"]), 
    Description: "使此技能傷害提升 5%。"},
    {ItemID: 50011,
    Name: "火屬性增強(10)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["火屬性"]), 
    Description: "使此技能傷害提升 10%。"},
    {ItemID: 50012,
    Name: "火屬性增強(15)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["火屬性"]), 
    Description: "使此技能傷害提升 15%。"},
    {ItemID: 50013,
    Name: "火屬性增強(20)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["火屬性"]), 
    Description: "使此技能傷害提升 20%。"},
    {ItemID: 50014,
    Name: "火屬性增強(25)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["火屬性"]), 
    Description: "使此技能傷害提升 25%。"},
    {ItemID: 50015,
    Name: "水屬性增強(5)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["水屬性"]), 
    Description: "使此技能傷害提升 5%。"},
    {ItemID: 50016,
    Name: "水屬性增強(10)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["水屬性"]), 
    Description: "使此技能傷害提升 10%。"},
    {ItemID: 50017,
    Name: "水屬性增強(15)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["水屬性"]), 
    Description: "使此技能傷害提升 15%。"},
    {ItemID: 50018,
    Name: "水屬性增強(20)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["水屬性"]), 
    Description: "使此技能傷害提升 20%。"},
    {ItemID: 50019,
    Name: "水屬性增強(25)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["水屬性"]), 
    Description: "使此技能傷害提升 25%。"},
    {ItemID: 50020,
    Name: "風屬性增強(5)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["風屬性"]), 
    Description: "使此技能傷害提升 5%。"},
    {ItemID: 50021,
    Name: "風屬性增強(10)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["風屬性"]), 
    Description: "使此技能傷害提升 10%。"},
    {ItemID: 50022,
    Name: "風屬性增強(15)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["風屬性"]), 
    Description: "使此技能傷害提升 15%。"},
    {ItemID: 50023,
    Name: "風屬性增強(20)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["風屬性"]), 
    Description: "使此技能傷害提升 20%。"},
    {ItemID: 50024,
    Name: "風屬性增強(25)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["風屬性"]), 
    Description: "使此技能傷害提升 25%。"},
    {ItemID: 50025,
    Name: "投射物分裂(1)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["投射物"]), 
    Description: "使此技能的投射物增加 1個。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50026,
    Name: "投射物分裂(2)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["投射物"]), 
    Description: "使此技能的投射物增加 2個。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50027,
    Name: "投射物分裂(3)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["投射物"]), 
    Description: "使此技能的投射物增加 3個。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50028,
    Name: "投射物分裂(4)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["投射物"]), 
    Description: "使此技能的投射物增加 4個。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50029,
    Name: "地面範圍擴大(1)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["地面"]), 
    Description: "使此技能的地面範圍向四方增加 1格。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50030,
    Name: "地面範圍擴大(2)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["地面"]), 
    Description: "使此技能的地面範圍向四方增加 2格。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50031,
    Name: "地面範圍擴大(3)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["地面"]), 
    Description: "使此技能的地面範圍向四方增加 3格。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50032,
    Name: "雙倍投擲(50)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["指定"]), 
    Description: "施放此技能時，立即重新對同一個目標施展一次相同技能，造成50%傷害。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50033,
    Name: "雙倍投擲(75)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["指定"]), 
    Description: "施放此技能時，立即重新對同一個目標施展一次相同技能，造成75%傷害。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50034,
    Name: "雙倍投擲(100)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["指定"]), 
    Description: "施放此技能時，立即重新對同一個目標施展一次相同技能，造成100%傷害。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50035,
    Name: "擊退(1)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "當此技能擊中目標時，將目標擊退0格。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50036,
    Name: "擊退(2)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "當此技能擊中目標時，將目標擊退0格。在同裝備重複使用此類寶石時，將以其中數值最高的發揮效果。"},
    {ItemID: 50037,
    Name: "燃燒之吻",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "對於每一個因為你而陷入燃燒狀態的目標，每1秒造成 40% 傷害(火屬性)，最多持續 10秒。"},
    {ItemID: 50038,
    Name: "冰封之吻",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "對於每一個因為你而陷入冰封狀態的目標，每1秒造成 40% 傷害(水屬性)，最多持續 10秒。"},
    {ItemID: 50039,
    Name: "痲痺之吻",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "對於每一個因為你而陷入痲痺狀態的目標，每1秒造成 40% 傷害(風屬性)，最多持續 10秒。"},
    {ItemID: 50040,
    Name: "吸取靈魂(1)",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "每擊殺1名目標，增加1%更多傷害，最多堆疊到10名目標，持續10秒。"},
    {ItemID: 50041,
    Name: "吸取靈魂(1.2)",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "每擊殺1名目標，增加1.2%更多傷害，最多堆疊到10名目標，持續10秒。"},
    {ItemID: 50042,
    Name: "吸取靈魂(1.4)",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "每擊殺1名目標，增加1.4%更多傷害，最多堆疊到10名目標，持續10秒。"},
    {ItemID: 50043,
    Name: "吸取靈魂(1.6)",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "每擊殺1名目標，增加1.6%更多傷害，最多堆疊到10名目標，持續10秒。"},
    {ItemID: 50044,
    Name: "吸取靈魂(1.8)",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "每擊殺1名目標，增加1.8%更多傷害，最多堆疊到10名目標，持續10秒。"},
    {ItemID: 50045,
    Name: "吸取靈魂(2)",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "每擊殺1名目標，增加2%更多傷害，最多堆疊到10名目標，持續10秒。"},
    {ItemID: 50046,
    Name: "折磨鎖鏈",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "對於一個同時陷入燃燒、冰封、痲痺的目標，你對它增加 25%更多傷害。"},
    {ItemID: 50047,
    Name: "疾走",
    Type: "Passive",
    Cooldown: 0,
    Flags: new Set([]), 
    Description: "增加25%移動速度。"},
    {ItemID: 50048,
    Name: "護身符",
    Type: "Passive",
    Cooldown: 60000,
    Flags: new Set([]), 
    Description: "即將死亡時，立刻恢復50%的生命值，並在接下來的4秒內，對所有傷害免疫。"},
    {ItemID: 50049,
    Name: "二連矢",
    Type: "Skill",
    SkillID: 46,
    CastTime: 0,
    Cooldown: 300,
    Flags: new Set(["指定","物理","遠距"]), 
    Description: "快速射出2支箭矢，造成150%傷害。"},
    {ItemID: 50050,
    Name: "銳利射擊",
    Type: "Skill",
    SkillID: 382,
    CastTime: 0,
    Cooldown: 0,
    Flags: new Set(["指定","範圍傷害","物理","遠距"]), 
    Description: "對指定敵人射出一道穿透性箭矢，並對路徑上所有敵人造成75%傷害。"},
    {ItemID: 50051,
    Name: "大暴雨",
    Type: "Skill",
    SkillID: 2418,
    CastTime: 500,
    Cooldown: 5000,
    Flags: new Set(["地面","範圍傷害","DOT","物理","遠距"]), 
    Description: "對指定地面的5格以內的區域，射出大量箭矢，對區域中的敵人每0.3秒造成50%傷害。"},
    {ItemID: 50052,
    Name: "彈射箭",
    Type: "Skill",
    SkillID: 2288,
    CastTime: 300,
    Cooldown: 3000,
    Flags: new Set(["指定","彈射","物理","遠距"]), 
    Description: "對目標造成300%傷害，並彈射給5個對象。"},
    {ItemID: 50053,
    Name: "衝鋒箭",
    Type: "Skill",
    SkillID: 148,
    CastTime: 300,
    Cooldown: 1500,
    Flags: new Set(["範圍傷害","投射物","物理","遠距"]), 
    Description: "強力射出一個箭矢，並且自身受到反作用力的效果退後3格。爆破箭矢撞擊到第一個目標時，造成對附近3格內的所有目標，造成300%傷害。"},
    {ItemID: 50054,
    Name: "遠距攻擊增強(5)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["遠距"]), 
    Description: "使此技能傷害提升 5%。"},
    {ItemID: 50055,
    Name: "遠距攻擊增強(10)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["遠距"]), 
    Description: "使此技能傷害提升 10%。"},
    {ItemID: 50056,
    Name: "遠距攻擊增強(15)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["遠距"]), 
    Description: "使此技能傷害提升 15%。"},
    {ItemID: 50057,
    Name: "遠距攻擊增強(20)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["遠距"]), 
    Description: "使此技能傷害提升 20%。"},
    {ItemID: 50058,
    Name: "遠距攻擊增強(25)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["遠距"]), 
    Description: "使此技能傷害提升 25%。"},
    {ItemID: 50059,
    Name: "狂擊",
    Type: "Skill",
    SkillID: 5,
    CastTime: 0,
    Cooldown: 300,
    Flags: new Set(["指定","物理","近距"]), 
    Description: "對目標造成150%傷害。"},
    {ItemID: 50060,
    Name: "怒暴",
    Type: "Skill",
    SkillID: 7,
    CastTime: 300,
    Cooldown: 3000,
    Flags: new Set(["自我施法","範圍傷害","物理","近距"]), 
    Description: "以自身為中心，對臨近4格內的目標造成200%傷害。"},
    {ItemID: 50061,
    Name: "騎乘攻擊",
    Type: "Skill",
    SkillID: 57,
    CastTime: 500,
    Cooldown: 1500,
    Flags: new Set(["地面","範圍傷害","物理","近距"]), 
    Description: "在自己前方的錐形範圍內造成400%傷害。"},
    {ItemID: 50062,
    Name: "靈氣劍波",
    Type: "Skill",
    SkillID: 2005,
    CastTime: 300,
    Cooldown: 1500,
    Flags: new Set(["範圍傷害","投射物","物理","近距"]), 
    Description: "使用劍揮出一道劍波，撞擊到第一個目標時，造成對附近3格內的所有目標，造成300%傷害。"},
    {ItemID: 50063,
    Name: "龍之氣息",
    Type: "Skill",
    SkillID: 2008,
    CastTime: 500,
    Cooldown: 5000,
    Flags: new Set(["地面","範圍傷害","物理","近距"]), 
    Description: "召喚龍的氣息攻擊目標，對臨近中心點4格內的目標造成400%傷害。"},
    {ItemID: 50064,
    Name: "近距攻擊增強(5)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["近距"]), 
    Description: "使此技能傷害提升 5%。"},
    {ItemID: 50065,
    Name: "近距攻擊增強(10)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["近距"]), 
    Description: "使此技能傷害提升 10%。"},
    {ItemID: 50066,
    Name: "近距攻擊增強(15)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["近距"]), 
    Description: "使此技能傷害提升 15%。"},
    {ItemID: 50067,
    Name: "近距攻擊增強(20)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["近距"]), 
    Description: "使此技能傷害提升 20%。"},
    {ItemID: 50068,
    Name: "近距攻擊增強(25)",
    Type: "Support",
    Cooldown: 0,
    Flags: new Set(["近距"]), 
    Description: "使此技能傷害提升 25%。"},
    {ItemID: 50069,
    Name: "旋轉火燄",
    Type: "Skill",
    SkillID: 10001,
    CastTime: 300,
    NpcSkill: true,
    Cooldown: 1500,
    Flags: new Set(["魔法","範圍傷害","火屬性",""]), 
    Description: "在一個一點召喚一道會旋轉的火牆，對於擊中的目標造成300%傷害"},
    {ItemID: 50070,
    Name: "完全治癒",
    Type: "Skill",
    SkillID: 687,
    CastTime: 0,
    NpcSkill: true,
    Cooldown: 0,
    Flags: new Set(["自我施法","物理","近距"]), 
    Description: "回復全部生命值"},
    {ItemID: 50071,
    Name: "火焰之壁",
    Type: "Skill",
    SkillID: 18,
    CastTime: 0,
    Cooldown: 0,
    Flags: new Set(["地面","範圍傷害","物理","近距"]), 
    Description: "回復全部生命值"}];
    export default data;
    