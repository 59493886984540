import './Page.css'

const Page = (props) => {
    return (
        <div className="filter">
            <div>
            {props.pages.map( item =>
                (<button key={item.filter} className={("filter__btn"+((item.filter === props.nowPage.filter)?" filter__active":""))} value={item.filter} onClick={props.changePage}>{item.name}</button>)
            )}
            </div>
        </div>
    );
}

export default Page;