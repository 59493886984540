import './Gem.css'

const GemFlag = (props) => {
	return <button className="card__flag__span" value={props.flag} onClick={props.changeFlag}>{props.flag}</button>
}

const getImage = function (type) {
	switch (type) {
		default:
		case 'Skill':
			return "img/large/725.png";
		case 'Support':
			return "img/large/729.png";
		case 'Passive':
			return "img/large/728.png";
	}
}

const displayTime = function (time) {
	// transform milliseconds to seconds and return it
	let seconds = time / 1000;
	return seconds + " 秒";
}

const Gem = (props) => {
	return (
		<div className="card">
			<div className="card__top">
				<img
					src={getImage(props.gem.Type)}
					alt={props.gem.Name}
					className="card__top__img"
				/>
				<h2 className="card__top__title">{props.gem.Name}</h2>
			</div>
			<div className="card__flag">
				{Array.from(props.gem.Flags).map(flag => {
					return <GemFlag flag={flag} key={flag} changeFlag={props.changeFlag} />
				})}
			</div>
			{ props.gem.CastTime ? <p className="card__description">詠唱時間：{ displayTime(props.gem.CastTime) }</p> : null}
			{ props.gem.Cooldown ? <p className="card__description">冷卻時間：{ displayTime(props.gem.Cooldown) }</p> : null}
			<p className="card__description">
				{props.gem.Description}
			</p>
		</div>
	);
}

export default Gem;