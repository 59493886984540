const EquipTable = [
	{
			"Name": "手杖",
			"FirstItemID": "1001",
			"ResourceID": 1601,
			"Attack": 10,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 1,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1001,"1": 1002,"2": 1003,"3": 1004,"4": 1005},
			"Affixes": [
				{ "Rate": 5000, "Info": [
					{ "ID": 30, "Min": 4.000000, "Max": 10.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "橡木魔杖",
			"FirstItemID": "1006",
			"ResourceID": 1604,
			"Attack": 13,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 4,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1006,"1": 1007,"2": 1008,"3": 1009,"4": 1010},
			"Affixes": [
				{ "Rate": 5000, "Info": [
					{ "ID": 30, "Min": 6.000000, "Max": 14.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "智慧魔杖",
			"FirstItemID": "1011",
			"ResourceID": 1607,
			"Attack": 16,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 7,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1011,"1": 1012,"2": 1013,"3": 1014,"4": 1015},
			"Affixes": [
				{ "Rate": 7500, "Info": [
					{ "ID": 30, "Min": 8.000000, "Max": 18.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "言靈魔杖",
			"FirstItemID": "1016",
			"ResourceID": 1610,
			"Attack": 20,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 10,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1016,"1": 1017,"2": 1018,"3": 1019,"4": 1020},
			"Affixes": [
				{ "Rate": 7500, "Info": [
					{ "ID": 30, "Min": 10.000000, "Max": 22.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "巫杖 魂咬",
			"FirstItemID": "1021",
			"ResourceID": 1613,
			"Attack": 26,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 15,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1021,"1": 1022,"2": 1023,"3": 1024,"4": 1025},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 12.000000, "Max": 26.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "聖杖 英靈",
			"FirstItemID": "1026",
			"ResourceID": 1614,
			"Attack": 33,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 20,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1026,"1": 1027,"2": 1028,"3": 1029,"4": 1030},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 14.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "骷髏魔杖",
			"FirstItemID": "1031",
			"ResourceID": 1615,
			"Attack": 42,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 26,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1031,"1": 1032,"2": 1033,"3": 1034,"4": 1035},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 16.000000, "Max": 34.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "羽翼之杖",
			"FirstItemID": "1036",
			"ResourceID": 1616,
			"Attack": 54,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 30,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1036,"1": 1037,"2": 1038,"3": 1039,"4": 1040},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 20.000000, "Max": 40.000000, "Version": 1}
				]},
				{ "Rate": 5000, "Info": [
					{ "ID": 9, "Min": 10.000000, "Max": 20.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "生存的魔杖",
			"FirstItemID": "1041",
			"ResourceID": 1617,
			"Attack": 70,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 38,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1041,"1": 1042,"2": 1043,"3": 1044,"4": 1045},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 24.000000, "Max": 46.000000, "Version": 1}
				]},
				{ "Rate": 5000, "Info": [
					{ "ID": 9, "Min": 15.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "高等生存的魔杖",
			"FirstItemID": "1046",
			"ResourceID": 1619,
			"Attack": 91,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 46,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1046,"1": 1047,"2": 1048,"3": 1049,"4": 1050},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 28.000000, "Max": 52.000000, "Version": 1}
				]},
				{ "Rate": 7500, "Info": [
					{ "ID": 9, "Min": 20.000000, "Max": 40.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "催眠師的魔杖",
			"FirstItemID": "1051",
			"ResourceID": 1622,
			"Attack": 118,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 52,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1051,"1": 1052,"2": 1053,"3": 1054,"4": 1055},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 32.000000, "Max": 58.000000, "Version": 1}
				]},
				{ "Rate": 7500, "Info": [
					{ "ID": 9, "Min": 25.000000, "Max": 50.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "銳利波之杖",
			"FirstItemID": "1056",
			"ResourceID": 1626,
			"Attack": 153,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 60,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1056,"1": 1057,"2": 1058,"3": 1059,"4": 1060},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 36.000000, "Max": 64.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 9, "Min": 30.000000, "Max": 60.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "紳士的權杖",
			"FirstItemID": "1061",
			"ResourceID": 1629,
			"Attack": 198,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 70,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1061,"1": 1062,"2": 1063,"3": 1064,"4": 1065},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 40.000000, "Max": 70.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 9, "Min": 35.000000, "Max": 70.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "水晶魔杖",
			"FirstItemID": "1066",
			"ResourceID": 1630,
			"Attack": 275,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 80,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1066,"1": 1067,"2": 1068,"3": 1069,"4": 1070},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 44.000000, "Max": 76.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 9, "Min": 40.000000, "Max": 80.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "驅魔聖杖",
			"FirstItemID": "1071",
			"ResourceID": 1631,
			"Attack": 334,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 90,
			"Critical": 25,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1071,"1": 1072,"2": 1073,"3": 1074,"4": 1075},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 48.000000, "Max": 82.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 9, "Min": 45.000000, "Max": 90.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "魔法師的魔杖",
			"FirstItemID": "1076",
			"ResourceID": 1632,
			"Attack": 434,
			"AttackSpeed": 1.000000,
			"Defense": 0,
			"Level": 100,
			"Critical": 25,
			"Type": "Weapon",
			"SubType": "Staff",
			"Slots": {"0": 1076,"1": 1077,"2": 1078,"3": 1079,"4": 1080},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 52.000000, "Max": 88.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 9, "Min": 50.000000, "Max": 100.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "弓",
			"FirstItemID": "1501",
			"ResourceID": 1701,
			"Attack": 10,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 1,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1501,"1": 1502,"2": 1503,"3": 1504,"4": 1505},
			"Affixes": [
				{ "Rate": 5000, "Info": [
					{ "ID": 30, "Min": 4.000000, "Max": 10.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "坎普茲弓",
			"FirstItemID": "1506",
			"ResourceID": 1704,
			"Attack": 13,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 4,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1506,"1": 1507,"2": 1508,"3": 1509,"4": 1510},
			"Affixes": [
				{ "Rate": 5000, "Info": [
					{ "ID": 30, "Min": 6.000000, "Max": 14.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "克羅伊特弓",
			"FirstItemID": "1511",
			"ResourceID": 1707,
			"Attack": 16,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 7,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1511,"1": 1512,"2": 1513,"3": 1514,"4": 1515},
			"Affixes": [
				{ "Rate": 7500, "Info": [
					{ "ID": 30, "Min": 8.000000, "Max": 18.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "十字弓",
			"FirstItemID": "1516",
			"ResourceID": 1710,
			"Attack": 20,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 10,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1516,"1": 1517,"2": 1518,"3": 1519,"4": 1520},
			"Affixes": [
				{ "Rate": 7500, "Info": [
					{ "ID": 30, "Min": 10.000000, "Max": 22.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "亞伯雷弓",
			"FirstItemID": "1521",
			"ResourceID": 1713,
			"Attack": 26,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 15,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1521,"1": 1522,"2": 1523,"3": 1524,"4": 1525},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 12.000000, "Max": 26.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "角弓",
			"FirstItemID": "1526",
			"ResourceID": 1714,
			"Attack": 33,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 20,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1526,"1": 1527,"2": 1528,"3": 1529,"4": 1530},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 14.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "獵人之弓",
			"FirstItemID": "1531",
			"ResourceID": 1718,
			"Attack": 42,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 26,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1531,"1": 1532,"2": 1533,"3": 1534,"4": 1535},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 16.000000, "Max": 34.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "盜賊之弓",
			"FirstItemID": "1536",
			"ResourceID": 1719,
			"Attack": 54,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 30,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1536,"1": 1537,"2": 1538,"3": 1539,"4": 1540},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 20.000000, "Max": 40.000000, "Version": 1}
				]},
				{ "Rate": 5000, "Info": [
					{ "ID": 7, "Min": 10.000000, "Max": 20.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "熾天使之弓",
			"FirstItemID": "1541",
			"ResourceID": 1720,
			"Attack": 70,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 38,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1541,"1": 1542,"2": 1543,"3": 1544,"4": 1545},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 24.000000, "Max": 46.000000, "Version": 1}
				]},
				{ "Rate": 5000, "Info": [
					{ "ID": 7, "Min": 15.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "十字機弩",
			"FirstItemID": "1546",
			"ResourceID": 1721,
			"Attack": 91,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 46,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1546,"1": 1547,"2": 1548,"3": 1549,"4": 1550},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 28.000000, "Max": 52.000000, "Version": 1}
				]},
				{ "Rate": 7500, "Info": [
					{ "ID": 7, "Min": 20.000000, "Max": 40.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "貝利斯塔強弩",
			"FirstItemID": "1551",
			"ResourceID": 1722,
			"Attack": 118,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 52,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1551,"1": 1552,"2": 1553,"3": 1554,"4": 1555},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 32.000000, "Max": 58.000000, "Version": 1}
				]},
				{ "Rate": 7500, "Info": [
					{ "ID": 7, "Min": 25.000000, "Max": 50.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "露娜弓",
			"FirstItemID": "1556",
			"ResourceID": 1723,
			"Attack": 153,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 60,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1556,"1": 1557,"2": 1558,"3": 1559,"4": 1560},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 36.000000, "Max": 64.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 30.000000, "Max": 60.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "天龍之翼",
			"FirstItemID": "1561",
			"ResourceID": 1724,
			"Attack": 198,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 70,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1561,"1": 1562,"2": 1563,"3": 1564,"4": 1565},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 40.000000, "Max": 70.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 35.000000, "Max": 70.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "吟遊詩人的弓",
			"FirstItemID": "1566",
			"ResourceID": 1725,
			"Attack": 275,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 80,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1566,"1": 1567,"2": 1568,"3": 1569,"4": 1570},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 44.000000, "Max": 76.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 40.000000, "Max": 80.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "雙發神弓",
			"FirstItemID": "1571",
			"ResourceID": 1736,
			"Attack": 334,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 90,
			"Critical": 25,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1571,"1": 1572,"2": 1573,"3": 1574,"4": 1575},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 48.000000, "Max": 82.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 45.000000, "Max": 90.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "伊克希恩的羽翼",
			"FirstItemID": "1576",
			"ResourceID": 1737,
			"Attack": 434,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 100,
			"Critical": 25,
			"Type": "Weapon",
			"SubType": "Bow",
			"Slots": {"0": 1576,"1": 1577,"2": 1578,"3": 1579,"4": 1580},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 52.000000, "Max": 88.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 50.000000, "Max": 100.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "長劍",
			"FirstItemID": "2001",
			"ResourceID": 1101,
			"Attack": 10,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 1,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2001,"1": 2002,"2": 2003,"3": 2004,"4": 2005},
			"Affixes": [
				{ "Rate": 5000, "Info": [
					{ "ID": 30, "Min": 4.000000, "Max": 10.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "圓月刀",
			"FirstItemID": "2006",
			"ResourceID": 1104,
			"Attack": 13,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 4,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2006,"1": 2007,"2": 2008,"3": 2009,"4": 2010},
			"Affixes": [
				{ "Rate": 5000, "Info": [
					{ "ID": 30, "Min": 6.000000, "Max": 14.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "厚刃劍",
			"FirstItemID": "2011",
			"ResourceID": 1107,
			"Attack": 16,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 7,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2011,"1": 2012,"2": 2013,"3": 2014,"4": 2015},
			"Affixes": [
				{ "Rate": 7500, "Info": [
					{ "ID": 30, "Min": 8.000000, "Max": 18.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "擊刺長劍",
			"FirstItemID": "2016",
			"ResourceID": 1110,
			"Attack": 20,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 10,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2016,"1": 2017,"2": 2018,"3": 2019,"4": 2020},
			"Affixes": [
				{ "Rate": 7500, "Info": [
					{ "ID": 30, "Min": 10.000000, "Max": 22.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "彎刀",
			"FirstItemID": "2021",
			"ResourceID": 1113,
			"Attack": 26,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 15,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2021,"1": 2022,"2": 2023,"3": 2024,"4": 2025},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 12.000000, "Max": 26.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "日本刀",
			"FirstItemID": "2026",
			"ResourceID": 1119,
			"Attack": 33,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 20,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2026,"1": 2027,"2": 2028,"3": 2029,"4": 2030},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 14.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "圓柄馬刀",
			"FirstItemID": "2031",
			"ResourceID": 1122,
			"Attack": 42,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 26,
			"Critical": 10,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2031,"1": 2032,"2": 2033,"3": 2034,"4": 2035},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 16.000000, "Max": 34.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "海東劍",
			"FirstItemID": "2036",
			"ResourceID": 1123,
			"Attack": 54,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 30,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2036,"1": 2037,"2": 2038,"3": 2039,"4": 2040},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 20.000000, "Max": 40.000000, "Version": 1}
				]},
				{ "Rate": 5000, "Info": [
					{ "ID": 7, "Min": 10.000000, "Max": 20.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "獸人刀",
			"FirstItemID": "2041",
			"ResourceID": 1124,
			"Attack": 70,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 38,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2041,"1": 2042,"2": 2043,"3": 2044,"4": 2045},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 24.000000, "Max": 46.000000, "Version": 1}
				]},
				{ "Rate": 5000, "Info": [
					{ "ID": 7, "Min": 15.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "騎兵之劍",
			"FirstItemID": "2046",
			"ResourceID": 1126,
			"Attack": 91,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 46,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2046,"1": 2047,"2": 2048,"3": 2049,"4": 2050},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 28.000000, "Max": 52.000000, "Version": 1}
				]},
				{ "Rate": 7500, "Info": [
					{ "ID": 7, "Min": 20.000000, "Max": 40.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "水紋之劍",
			"FirstItemID": "2051",
			"ResourceID": 1129,
			"Attack": 118,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 52,
			"Critical": 15,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2051,"1": 2052,"2": 2053,"3": 2054,"4": 2055},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 32.000000, "Max": 58.000000, "Version": 1}
				]},
				{ "Rate": 7500, "Info": [
					{ "ID": 7, "Min": 25.000000, "Max": 50.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "多刃尖刺刀",
			"FirstItemID": "2056",
			"ResourceID": 1130,
			"Attack": 153,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 60,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2056,"1": 2057,"2": 2058,"3": 2059,"4": 2060},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 36.000000, "Max": 64.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 30.000000, "Max": 60.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "水靈之刀",
			"FirstItemID": "2061",
			"ResourceID": 1131,
			"Attack": 198,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 70,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2061,"1": 2062,"2": 2063,"3": 2064,"4": 2065},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 40.000000, "Max": 70.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 35.000000, "Max": 70.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "秘劍 慕戀",
			"FirstItemID": "2066",
			"ResourceID": 1132,
			"Attack": 275,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 80,
			"Critical": 20,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2066,"1": 2067,"2": 2068,"3": 2069,"4": 2070},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 44.000000, "Max": 76.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 40.000000, "Max": 80.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "火靈之劍",
			"FirstItemID": "2071",
			"ResourceID": 1133,
			"Attack": 334,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 90,
			"Critical": 25,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2071,"1": 2072,"2": 2073,"3": 2074,"4": 2075},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 48.000000, "Max": 82.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 45.000000, "Max": 90.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "利剪劍",
			"FirstItemID": "2076",
			"ResourceID": 1134,
			"Attack": 434,
			"AttackSpeed": 1.300000,
			"Defense": 0,
			"Level": 100,
			"Critical": 25,
			"Type": "Weapon",
			"SubType": "Sword",
			"Slots": {"0": 2076,"1": 2077,"2": 2078,"3": 2079,"4": 2080},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 30, "Min": 52.000000, "Max": 88.000000, "Version": 1}
				]},
				{ "Rate": 10000, "Info": [
					{ "ID": 7, "Min": 50.000000, "Max": 100.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "鐵盾",
			"FirstItemID": "5001",
			"ResourceID": 2101,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 5,
			"Level": 1,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5001,"1": 5002,"2": 5003,"3": 5004,"4": 5005},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 100.000000, "Max": 300.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "圓盾",
			"FirstItemID": "5006",
			"ResourceID": 2103,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 12,
			"Level": 4,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5006,"1": 5007,"2": 5008,"3": 5009,"4": 5010},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 150.000000, "Max": 400.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "鋼盾",
			"FirstItemID": "5011",
			"ResourceID": 2105,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 17,
			"Level": 7,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5011,"1": 5012,"2": 5013,"3": 5014,"4": 5015},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 200.000000, "Max": 500.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "鏡盾",
			"FirstItemID": "5016",
			"ResourceID": 2107,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 25,
			"Level": 10,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5016,"1": 5017,"2": 5018,"3": 5019,"4": 5020},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 250.000000, "Max": 600.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "謊言記錄本",
			"FirstItemID": "5021",
			"ResourceID": 2109,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 33,
			"Level": 15,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5021,"1": 5022,"2": 5023,"3": 5024,"4": 5025},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 300.000000, "Max": 700.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "聖耀之盾",
			"FirstItemID": "5026",
			"ResourceID": 2110,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 42,
			"Level": 20,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5026,"1": 5027,"2": 5028,"3": 5029,"4": 5030},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 350.000000, "Max": 800.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "大天使之盾",
			"FirstItemID": "5031",
			"ResourceID": 2111,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 50,
			"Level": 26,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5031,"1": 5032,"2": 5033,"3": 5034,"4": 5035},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 400.000000, "Max": 900.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "石製圓盾",
			"FirstItemID": "5036",
			"ResourceID": 2114,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 67,
			"Level": 30,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5036,"1": 5037,"2": 5038,"3": 5039,"4": 5040},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 450.000000, "Max": 1000.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "巴基力亞鋼盾",
			"FirstItemID": "5041",
			"ResourceID": 2115,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 83,
			"Level": 38,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5041,"1": 5042,"2": 5043,"3": 5044,"4": 5045},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 500.000000, "Max": 1100.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "天使之保護",
			"FirstItemID": "5046",
			"ResourceID": 2116,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 100,
			"Level": 46,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5046,"1": 5047,"2": 5048,"3": 5049,"4": 5050},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 550.000000, "Max": 1200.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "白金鋼盾",
			"FirstItemID": "5051",
			"ResourceID": 2122,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 125,
			"Level": 52,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5051,"1": 5052,"2": 5053,"3": 5054,"4": 5055},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 600.000000, "Max": 1300.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "歐羅萊昂的鐵盤",
			"FirstItemID": "5056",
			"ResourceID": 2123,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 167,
			"Level": 60,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5056,"1": 5057,"2": 5058,"3": 5059,"4": 5060},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 650.000000, "Max": 1400.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "刺針盾牌",
			"FirstItemID": "5061",
			"ResourceID": 2124,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 208,
			"Level": 70,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5061,"1": 5062,"2": 5063,"3": 5064,"4": 5065},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 700.000000, "Max": 1500.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "強硬鋼盾",
			"FirstItemID": "5066",
			"ResourceID": 2125,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 250,
			"Level": 80,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5066,"1": 5067,"2": 5068,"3": 5069,"4": 5070},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 750.000000, "Max": 1600.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "蒟蒻盾牌",
			"FirstItemID": "5071",
			"ResourceID": 2126,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 333,
			"Level": 90,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5071,"1": 5072,"2": 5073,"3": 5074,"4": 5075},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 800.000000, "Max": 1700.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "十字鋼盾",
			"FirstItemID": "5076",
			"ResourceID": 2130,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 417,
			"Level": 100,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Shield",
			"Slots": {"0": 5076,"1": 5077,"2": 5078,"3": 5079,"4": 5080},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 19, "Min": 850.000000, "Max": 1800.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "輕便鞋",
			"FirstItemID": "6001",
			"ResourceID": 2401,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 2,
			"Level": 1,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6001,"1": 6002,"2": 6003,"3": 6004,"4": 6005},
			"Memo": "移動速度 +10%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 5.000000, "Max": 10.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "長靴",
			"FirstItemID": "6006",
			"ResourceID": 2403,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 5,
			"Level": 4,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6006,"1": 6007,"2": 6008,"3": 6009,"4": 6010},
			"Memo": "移動速度 +10%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 5.000000, "Max": 11.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "戰士長靴",
			"FirstItemID": "6011",
			"ResourceID": 2405,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 7,
			"Level": 7,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6011,"1": 6012,"2": 6013,"3": 6014,"4": 6015},
			"Memo": "移動速度 +10%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 5.000000, "Max": 12.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "玻璃鞋",
			"FirstItemID": "6016",
			"ResourceID": 2407,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 10,
			"Level": 10,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6016,"1": 6017,"2": 6018,"3": 6019,"4": 6020},
			"Memo": "移動速度 +10%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 5.000000, "Max": 13.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "腳鍊",
			"FirstItemID": "6021",
			"ResourceID": 2408,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 13,
			"Level": 15,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6021,"1": 6022,"2": 6023,"3": 6024,"4": 6025},
			"Memo": "移動速度 +10%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 7.000000, "Max": 14.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "重靴",
			"FirstItemID": "6026",
			"ResourceID": 2411,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 17,
			"Level": 20,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6026,"1": 6027,"2": 6028,"3": 6029,"4": 6030},
			"Memo": "移動速度 +10%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 7.000000, "Max": 15.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "安全鞋",
			"FirstItemID": "6031",
			"ResourceID": 2413,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 20,
			"Level": 26,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6031,"1": 6032,"2": 6033,"3": 6034,"4": 6035},
			"Memo": "移動速度 +10%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 7.000000, "Max": 16.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "傅里哥長靴",
			"FirstItemID": "6036",
			"ResourceID": 2417,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 27,
			"Level": 30,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6036,"1": 6037,"2": 6038,"3": 6039,"4": 6040},
			"Memo": "移動速度 +15%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 7.000000, "Max": 17.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "迪塔樂長靴",
			"FirstItemID": "6041",
			"ResourceID": 2418,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 33,
			"Level": 38,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6041,"1": 6042,"2": 6043,"3": 6044,"4": 6045},
			"Memo": "移動速度 +15%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 9.000000, "Max": 18.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "哥夫內軍靴",
			"FirstItemID": "6046",
			"ResourceID": 2419,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 40,
			"Level": 46,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6046,"1": 6047,"2": 6048,"3": 6049,"4": 6050},
			"Memo": "移動速度 +15%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 9.000000, "Max": 19.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "天使之降臨",
			"FirstItemID": "6051",
			"ResourceID": 2420,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 50,
			"Level": 52,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6051,"1": 6052,"2": 6053,"3": 6054,"4": 6055},
			"Memo": "移動速度 +15%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 9.000000, "Max": 20.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "巴基力的長靴",
			"FirstItemID": "6056",
			"ResourceID": 2421,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 67,
			"Level": 60,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6056,"1": 6057,"2": 6058,"3": 6059,"4": 6060},
			"Memo": "移動速度 +20%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 9.000000, "Max": 22.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "高級輕便鞋",
			"FirstItemID": "6061",
			"ResourceID": 2422,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 83,
			"Level": 70,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6061,"1": 6062,"2": 6063,"3": 6064,"4": 6065},
			"Memo": "移動速度 +20%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 11.000000, "Max": 24.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "變形長靴",
			"FirstItemID": "6066",
			"ResourceID": 2423,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 100,
			"Level": 80,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6066,"1": 6067,"2": 6068,"3": 6069,"4": 6070},
			"Memo": "移動速度 +20%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 11.000000, "Max": 26.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "防水長靴",
			"FirstItemID": "6071",
			"ResourceID": 2424,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 133,
			"Level": 90,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6071,"1": 6072,"2": 6073,"3": 6074,"4": 6075},
			"Memo": "移動速度 +20%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 11.000000, "Max": 28.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "黑色皮革長靴",
			"FirstItemID": "6076",
			"ResourceID": 2425,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 167,
			"Level": 100,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Shoes",
			"Slots": {"0": 6076,"1": 6077,"2": 6078,"3": 6079,"4": 6080},
			"Memo": "移動速度 +20%",
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 32, "Min": 11.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "棉襯衫",
			"FirstItemID": "7001",
			"ResourceID": 2301,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 3,
			"Level": 1,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7001,"1": 7002,"2": 7003,"3": 7004,"4": 7005},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 5.000000, "Max": 10.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "皮製外套",
			"FirstItemID": "7006",
			"ResourceID": 2303,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 7,
			"Level": 4,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7006,"1": 7007,"2": 7008,"3": 7009,"4": 7010},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 6.000000, "Max": 12.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "冒險衣",
			"FirstItemID": "7011",
			"ResourceID": 2305,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 10,
			"Level": 7,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7011,"1": 7012,"2": 7013,"3": 7014,"4": 7015},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 7.000000, "Max": 14.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "大衣",
			"FirstItemID": "7016",
			"ResourceID": 2307,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 15,
			"Level": 10,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7016,"1": 7017,"2": 7018,"3": 7019,"4": 7020},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 8.000000, "Max": 16.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "長大衣",
			"FirstItemID": "7021",
			"ResourceID": 2309,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 20,
			"Level": 15,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7021,"1": 7022,"2": 7023,"3": 7024,"4": 7025},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 9.000000, "Max": 18.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "毛皮大衣",
			"FirstItemID": "7026",
			"ResourceID": 2311,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 25,
			"Level": 20,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7026,"1": 7027,"2": 7028,"3": 7029,"4": 7030},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 10.000000, "Max": 20.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "鐵製鎧甲",
			"FirstItemID": "7031",
			"ResourceID": 2312,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 30,
			"Level": 26,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7031,"1": 7032,"2": 7033,"3": 7034,"4": 7035},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 11.000000, "Max": 22.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "鋼鐵鎖子甲",
			"FirstItemID": "7036",
			"ResourceID": 2314,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 40,
			"Level": 30,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7036,"1": 7037,"2": 7038,"3": 7039,"4": 7040},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 12.000000, "Max": 24.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "鋼鐵鎧甲",
			"FirstItemID": "7041",
			"ResourceID": 2316,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 50,
			"Level": 38,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7041,"1": 7042,"2": 7043,"3": 7044,"4": 7045},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 13.000000, "Max": 26.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "祕衣 美德",
			"FirstItemID": "7046",
			"ResourceID": 2318,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 60,
			"Level": 46,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7046,"1": 7047,"2": 7048,"3": 7049,"4": 7050},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 14.000000, "Max": 28.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "祕衣 惡德",
			"FirstItemID": "7051",
			"ResourceID": 2319,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 75,
			"Level": 52,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7051,"1": 7052,"2": 7053,"3": 7054,"4": 7055},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 15.000000, "Max": 30.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "西裝外套",
			"FirstItemID": "7056",
			"ResourceID": 2320,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 100,
			"Level": 60,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7056,"1": 7057,"2": 7058,"3": 7059,"4": 7060},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 16.000000, "Max": 32.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "絲質外袍",
			"FirstItemID": "7061",
			"ResourceID": 2321,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 125,
			"Level": 70,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7061,"1": 7062,"2": 7063,"3": 7064,"4": 7065},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 17.000000, "Max": 34.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "聖職之袍",
			"FirstItemID": "7066",
			"ResourceID": 2323,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 150,
			"Level": 80,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7066,"1": 7067,"2": 7068,"3": 7069,"4": 7070},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 18.000000, "Max": 36.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "聖職之衣",
			"FirstItemID": "7071",
			"ResourceID": 2325,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 200,
			"Level": 90,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7071,"1": 7072,"2": 7073,"3": 7074,"4": 7075},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 19.000000, "Max": 38.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "天衣 月舞",
			"FirstItemID": "7076",
			"ResourceID": 2327,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 250,
			"Level": 100,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Armor",
			"Slots": {"0": 7076,"1": 7077,"2": 7078,"3": 7079,"4": 7080},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 20, "Min": 20.000000, "Max": 40.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "連帽披肩",
			"FirstItemID": "8001",
			"ResourceID": 2501,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 2,
			"Level": 1,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8001,"1": 8002,"2": 8003,"3": 8004,"4": 8005},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 1.000000, "Max": 3.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "披肩",
			"FirstItemID": "8006",
			"ResourceID": 2503,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 5,
			"Level": 4,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8006,"1": 8007,"2": 8008,"3": 8009,"4": 8010},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 1.000000, "Max": 3.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "斗篷",
			"FirstItemID": "8011",
			"ResourceID": 2505,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 7,
			"Level": 7,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8011,"1": 8012,"2": 8013,"3": 8014,"4": 8015},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 1.000000, "Max": 4.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "伯爵斗篷",
			"FirstItemID": "8016",
			"ResourceID": 2507,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 10,
			"Level": 10,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8016,"1": 8017,"2": 8018,"3": 8019,"4": 8020},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 1.000000, "Max": 4.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "生存斗篷",
			"FirstItemID": "8021",
			"ResourceID": 2509,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 13,
			"Level": 15,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8021,"1": 8022,"2": 8023,"3": 8024,"4": 8025},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 2.000000, "Max": 5.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "邪骸戰士的斗篷",
			"FirstItemID": "8026",
			"ResourceID": 2511,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 17,
			"Level": 20,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8026,"1": 8027,"2": 8028,"3": 8029,"4": 8030},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 2.000000, "Max": 5.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "薄紗圍巾",
			"FirstItemID": "8031",
			"ResourceID": 2513,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 20,
			"Level": 26,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8031,"1": 8032,"2": 8033,"3": 8034,"4": 8035},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 2.000000, "Max": 7.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "護肩甲",
			"FirstItemID": "8036",
			"ResourceID": 2514,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 27,
			"Level": 30,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8036,"1": 8037,"2": 8038,"3": 8039,"4": 8040},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 2.000000, "Max": 7.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "老鷹之翅膀",
			"FirstItemID": "8041",
			"ResourceID": 2515,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 33,
			"Level": 38,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8041,"1": 8042,"2": 8043,"3": 8044,"4": 8045},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 3.000000, "Max": 9.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "獵鷹披肩",
			"FirstItemID": "8046",
			"ResourceID": 2516,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 40,
			"Level": 46,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8046,"1": 8047,"2": 8048,"3": 8049,"4": 8050},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 3.000000, "Max": 9.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "貝利的斗篷",
			"FirstItemID": "8051",
			"ResourceID": 2517,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 50,
			"Level": 52,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8051,"1": 8052,"2": 8053,"3": 8054,"4": 8055},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 3.000000, "Max": 11.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "摩菲斯披肩",
			"FirstItemID": "8056",
			"ResourceID": 2518,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 67,
			"Level": 60,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8056,"1": 8057,"2": 8058,"3": 8059,"4": 8060},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 3.000000, "Max": 11.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "茉莉雅娜斗篷",
			"FirstItemID": "8061",
			"ResourceID": 2519,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 83,
			"Level": 70,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8061,"1": 8062,"2": 8063,"3": 8064,"4": 8065},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 4.000000, "Max": 13.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "哥夫內肩飾",
			"FirstItemID": "8066",
			"ResourceID": 2520,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 100,
			"Level": 80,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8066,"1": 8067,"2": 8068,"3": 8069,"4": 8070},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 4.000000, "Max": 13.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "巴基力的斗篷",
			"FirstItemID": "8071",
			"ResourceID": 2524,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 133,
			"Level": 90,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8071,"1": 8072,"2": 8073,"3": 8074,"4": 8075},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 4.000000, "Max": 15.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "龍的呼吸",
			"FirstItemID": "8076",
			"ResourceID": 2527,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 167,
			"Level": 100,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Garment",
			"Slots": {"0": 8076,"1": 8077,"2": 8078,"3": 8079,"4": 8080},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 31, "Min": 4.000000, "Max": 15.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "紫蝶髮帶",
			"FirstItemID": "11001",
			"ResourceID": 2208,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 2,
			"Level": 1,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11001,"1": 11002,"2": 11003,"3": 11004,"4": 11005},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 5.000000, "Max": 10.000000, "Version": 1},
					{ "ID": 50, "Min": 5.000000, "Max": 10.000000, "Version": 1},
					{ "ID": 51, "Min": 5.000000, "Max": 10.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "髮箍",
			"FirstItemID": "11006",
			"ResourceID": 2210,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 5,
			"Level": 4,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11006,"1": 11007,"2": 11008,"3": 11009,"4": 11010},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 6.000000, "Max": 13.000000, "Version": 1},
					{ "ID": 50, "Min": 6.000000, "Max": 13.000000, "Version": 1},
					{ "ID": 51, "Min": 6.000000, "Max": 13.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "方頭巾",
			"FirstItemID": "11011",
			"ResourceID": 2211,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 7,
			"Level": 7,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11011,"1": 11012,"2": 11013,"3": 11014,"4": 11015},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 7.000000, "Max": 16.000000, "Version": 1},
					{ "ID": 50, "Min": 7.000000, "Max": 16.000000, "Version": 1},
					{ "ID": 51, "Min": 7.000000, "Max": 16.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "聖職之帽",
			"FirstItemID": "11016",
			"ResourceID": 2216,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 10,
			"Level": 10,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11016,"1": 11017,"2": 11018,"3": 11019,"4": 11020},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 8.000000, "Max": 19.000000, "Version": 1},
					{ "ID": 50, "Min": 8.000000, "Max": 19.000000, "Version": 1},
					{ "ID": 51, "Min": 8.000000, "Max": 19.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "漁夫帽",
			"FirstItemID": "11021",
			"ResourceID": 2220,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 13,
			"Level": 15,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11021,"1": 11022,"2": 11023,"3": 11024,"4": 11025},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 9.000000, "Max": 22.000000, "Version": 1},
					{ "ID": 50, "Min": 9.000000, "Max": 22.000000, "Version": 1},
					{ "ID": 51, "Min": 9.000000, "Max": 22.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "圓帽",
			"FirstItemID": "11026",
			"ResourceID": 2222,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 17,
			"Level": 20,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11026,"1": 11027,"2": 11028,"3": 11029,"4": 11030},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 10.000000, "Max": 25.000000, "Version": 1},
					{ "ID": 50, "Min": 10.000000, "Max": 25.000000, "Version": 1},
					{ "ID": 51, "Min": 10.000000, "Max": 25.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "無邊帽",
			"FirstItemID": "11031",
			"ResourceID": 2226,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 20,
			"Level": 26,
			"Critical": 10,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11031,"1": 11032,"2": 11033,"3": 11034,"4": 11035},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 11.000000, "Max": 28.000000, "Version": 1},
					{ "ID": 50, "Min": 11.000000, "Max": 28.000000, "Version": 1},
					{ "ID": 51, "Min": 11.000000, "Max": 28.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "金屬頭盔",
			"FirstItemID": "11036",
			"ResourceID": 2228,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 27,
			"Level": 30,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11036,"1": 11037,"2": 11038,"3": 11039,"4": 11040},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 12.000000, "Max": 31.000000, "Version": 1},
					{ "ID": 50, "Min": 12.000000, "Max": 31.000000, "Version": 1},
					{ "ID": 51, "Min": 12.000000, "Max": 31.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "寶石頭盔",
			"FirstItemID": "11041",
			"ResourceID": 2230,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 33,
			"Level": 38,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11041,"1": 11042,"2": 11043,"3": 11044,"4": 11045},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 13.000000, "Max": 34.000000, "Version": 1},
					{ "ID": 50, "Min": 13.000000, "Max": 34.000000, "Version": 1},
					{ "ID": 51, "Min": 13.000000, "Max": 34.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "頭箍",
			"FirstItemID": "11046",
			"ResourceID": 2232,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 40,
			"Level": 46,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11046,"1": 11047,"2": 11048,"3": 11049,"4": 11050},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 14.000000, "Max": 37.000000, "Version": 1},
					{ "ID": 50, "Min": 14.000000, "Max": 37.000000, "Version": 1},
					{ "ID": 51, "Min": 14.000000, "Max": 37.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "紅蝶髮帶",
			"FirstItemID": "11051",
			"ResourceID": 2244,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 50,
			"Level": 52,
			"Critical": 15,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11051,"1": 11052,"2": 11053,"3": 11054,"4": 11055},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 15.000000, "Max": 40.000000, "Version": 1},
					{ "ID": 50, "Min": 15.000000, "Max": 40.000000, "Version": 1},
					{ "ID": 51, "Min": 15.000000, "Max": 40.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "神聖之帽",
			"FirstItemID": "11056",
			"ResourceID": 2251,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 67,
			"Level": 60,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11056,"1": 11057,"2": 11058,"3": 11059,"4": 11060},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 16.000000, "Max": 43.000000, "Version": 1},
					{ "ID": 50, "Min": 16.000000, "Max": 43.000000, "Version": 1},
					{ "ID": 51, "Min": 16.000000, "Max": 43.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "魔法帽",
			"FirstItemID": "11061",
			"ResourceID": 2252,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 83,
			"Level": 70,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11061,"1": 11062,"2": 11063,"3": 11064,"4": 11065},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 17.000000, "Max": 46.000000, "Version": 1},
					{ "ID": 50, "Min": 17.000000, "Max": 46.000000, "Version": 1},
					{ "ID": 51, "Min": 17.000000, "Max": 46.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "頭巾",
			"FirstItemID": "11066",
			"ResourceID": 2275,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 100,
			"Level": 80,
			"Critical": 20,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11066,"1": 11067,"2": 11068,"3": 11069,"4": 11070},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 18.000000, "Max": 49.000000, "Version": 1},
					{ "ID": 50, "Min": 18.000000, "Max": 49.000000, "Version": 1},
					{ "ID": 51, "Min": 18.000000, "Max": 49.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "普隆德拉軍帽",
			"FirstItemID": "11071",
			"ResourceID": 2261,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 133,
			"Level": 90,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11071,"1": 11072,"2": 11073,"3": 11074,"4": 11075},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 19.000000, "Max": 52.000000, "Version": 1},
					{ "ID": 50, "Min": 19.000000, "Max": 52.000000, "Version": 1},
					{ "ID": 51, "Min": 19.000000, "Max": 52.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "草帽",
			"FirstItemID": "11076",
			"ResourceID": 2280,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 167,
			"Level": 100,
			"Critical": 25,
			"Type": "Armor",
			"SubType": "Head",
			"Slots": {"0": 11076,"1": 11077,"2": 11078,"3": 11079,"4": 11080},
			"Affixes": [
				{ "Rate": 10000, "Info": [
					{ "ID": 49, "Min": 20.000000, "Max": 55.000000, "Version": 1},
					{ "ID": 50, "Min": 20.000000, "Max": 55.000000, "Version": 1},
					{ "ID": 51, "Min": 20.000000, "Max": 55.000000, "Version": 1}
				]}
			]
	},
	{
			"Name": "銀戒指",
			"FirstItemID": "12001",
			"ResourceID": 2611,
			"Attack": 0,
			"AttackSpeed": 0.000000,
			"Defense": 0,
			"Level": 1,
			"Type": "Armor",
			"SubType": "Acc",
			"Slots": {"0": 12001,"1": 12002,"2": 12003,"3": 12004,"4": 12005},
			"Affixes": [
				{ "Rate": 10000, "Info": [
				]},
				{ "Rate": 10000, "Info": [
				]},
				{ "Rate": 10000, "Info": [
				]}
			]
	}
	]
	export default EquipTable;
	