import './GemFlagFilter.css'

const GemFlag = (props) => {
    return <button className="filter__btn filter__active" onClick={props.changeFlag} value={props.filter}>{props.filter}</button>
};

const GemFlagFilter = (props) => {
    if (props.nowFilter === undefined)
        return (<div></div>);
    else
        return (
        <div className='filter'>
        {
            Array.from(props.nowFilter).map( filter => {
                return <GemFlag filter={filter} changeFlag={props.changeFlag}/>
            })
        }
        </div>
        );
}

export default GemFlagFilter;