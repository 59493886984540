import './Gem.css'
import AffixTable from './data/affixes'

const AffixSubInfo = (props) => {
	const AffixDisp = function (Number, Affix) {
		const AffixData = AffixTable.get(Affix.ID);
		let Op = "+";
		let Min = Affix.Min;
		let Max = Affix.Max;
		let End = "";
	
		if (AffixData.ImplementWay === "Sub") {
		Op = "-";
		} else if (AffixData.ImplementWay === "Multiply") {
		Min = Min + 100;
		Max = Max + 100;
		Op = "*";
		} else if (AffixData.ImplementWay === "Divide") {
		Min = Min + 100;
		Max = Max + 100;
		Op = "/";
		}
	
		let Name = AffixData.Name;
	
		let AppendStr;
		if (Name.includes("{}")) {
		if (AffixData.Percentage) {
			End = "%";
		}
	
		if (Min === Max) {
			AppendStr = Op + "[" + Min + End + "]";
		} else {
			AppendStr = Op + "[" + Min + End + "-" + Max + End + "]";
		}
	
		return Number + ")" + Name.replace("{}", AppendStr);
		} else {
		if (AffixData.Percentage) {
			End = "%";
		}
	
		AppendStr = Op + "[" + Min + End + "-" + Max + End + "]";
	
		return Number + ")" + Name + AppendStr;
		}
	};

	return (
		<div>  {AffixDisp(props.index+1, props.affix)}</div>
	);
}

const AffixPool = (props) => {
	return (
		<div className={"card__affixinfo card__affixinfo_color"+(props.index+1)}>
		<div>詞綴池#{props.index+1} {props.drop.Rate/100}%掉落機率</div>
		{
			props.drop.Info.map((affix, index) => {
				return <AffixSubInfo key={index} affix={affix} index={index}/>;
			})
		}
		</div>
	)
}

const Affixes = (props) => {
	return (
		<div>
			<div>詞綴掉落清單</div>
			{
				props.affixes.map( (drop, index) => {
					return <AffixPool key={index} index={index} drop={drop}/>;
				})
			}
		</div>
	);
}

const AffixCount = (affixes) => {
	let Count = 0;

	affixes.forEach( item => {
		Count += item.Info.length;
	});

	return Count;
}

const EquipInfo = (props) => {
	return (
		<div className="card__description">
			<div>{props.equip.StartChapter ? ("第" + props.equip.StartChapter +"章以上的祕境掉落") : ""}</div>
			<div>{props.equip.Memo ? props.equip.Memo : ""}</div>
			<div>{props.equip.Attack ? "攻擊力：" + props.equip.Attack : ""}</div>
			<div>{props.equip.Critical ? "暴擊率：" + props.equip.Critical +"%": ""}</div>
			<div>{props.equip.AttackSpeed ? "攻速：" + props.equip.AttackSpeed : ""}</div>
			<div>{props.equip.Defense ? "防禦：" + props.equip.Defense : ""}</div>
			<div>等級需求：{props.equip.Level}</div>
			{
				AffixCount(props.equip.Affixes) > 0 ?
				<Affixes affixes={props.equip.Affixes}/> :
				undefined
			}
		</div>
	);
}

const Equip = (props) => {
	return (
		<div className="card">
			<div className="card__top">
				<img
					src={"img/large/"+props.equip.ResourceID+".png"}
					alt={props.equip.Name}
					className="card__top__img"
				/>
				<h2 className="card__top__title">{props.equip.Name}</h2>
			</div>
			<EquipInfo equip={props.equip}/>
		</div>
	);
}

export default Equip;